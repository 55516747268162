/* .ringsProgress {
	transition: 'all 3.5s ease-out';
	-moz-transition: height 1s ease;
	-webkit-transition: height 1s ease;
	-o-transition: height 1s ease;
	transition: height 1s ease;
} */

.animate {
  height: 0px;
  width: 200px;
  -moz-transition: all 0s ease;
  -webkit-transition: all 0s ease;
  -o-transition: all 0s ease;
  transition: all 0s ease;
}

.animate.grow {
  height: calc(100vh - 330px);
  width: 400px;
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.expand {
  width: 100%;
  max-width: 100px;
  /* transform: scale(1); */
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.expand.grower {
  width: 100%;
  max-width: 200px;
  /* transform: scale(1.5); */
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.rotate {
  transform: rotate(0deg);
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.rotate.reverse {
  transform: rotate(-180deg);
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.visible {
  opacity: 0;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.visibleShow {
  opacity: 1;
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.ringsCase {
  height: 150px;
  width: 150px;
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.ringsCaseExpand {
  height: 400px;
  width: 400px;
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
